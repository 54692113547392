<template>
  <main>
    <form @submit.prevent="submit" class="flex flex-col" action="">
      <span class="font-medium text-dark-200 text-sm">Address information</span>
      <!-- <easiTextInput
        placeholder="House number"
        v-model="args.addressInformation.houseNumber"
      ></easiTextInput> -->
      <easiTextInput placeholder="Street" v-model="args.addressInformation.street"></easiTextInput>
      <!-- <easiTextInput placeholder="Local government area" v-model="args.addressInformation.lgaName"></easiTextInput> -->
      <!-- <easiTextInput
        placeholder="Landmark bus stop"
        v-model="args.addressInformation.landmark"
      ></easiTextInput> -->

      <easiTextInput placeholder="City" v-model="args.addressInformation.city"></easiTextInput>

      <div class="flex gap-2 mt-5 items-center">
        <easiSelectInput2
          :options="countryOption"
          @update="updateCountry($event, 'addressInformation', 'country')"
          :value="args.addressInformation.country"
          placeholder="Country"
          class="w-full"
        ></easiSelectInput2>
        <easiSelectInput2
          :options="stateOption"
          @update="updateState($event, 'addressInformation', 'state')"
          :value="args.addressInformation.state"
          placeholder="State"
          class="w-full"
        ></easiSelectInput2>
      </div>
      <easiSelectInput2
        :options="lgOption"
        @update="args.addressInformation.lgaName = $event"
        :value="args.addressInformation.lgaName"
        placeholder="Local government"
        class="w-full mt-3"
      ></easiSelectInput2>
      <div class="justify-center my-3">
        <UploadComponent
          label="Upload proof of residential address"
          :listInfo="['It can be either Utilities bill e.g Power or Waste management or Tenancy receipt.']"
          infoHeader="Proof of residential address"
          :dottedNew="true"
          @fileUrl="addressFile = $event"
        />
      </div>

      <span class="font-medium text-dark-200 text-sm my-3">Identity</span>
      <easiSelectInput2
        placeholder="Select ID type"
        :options="Options"
        @update="idArgs.idType = $event"
        :value="idArgs.idType"
        class="w-full"
      ></easiSelectInput2>

      <easiTextInput
        v-model="idArgs.value"
        v-if="idArgs.idType === 'international_passport'"
        placeholder="Enter passport number"
      ></easiTextInput>
      <easiTextInput
        v-model="idArgs.value"
        v-if="idArgs.idType === 'voters_card'"
        placeholder="Enter voters card number"
      ></easiTextInput>
      <easiTextInput
        v-model="idArgs.value"
        v-if="idArgs.idType === 'drivers_license'"
        placeholder="Enter license number"
      ></easiTextInput>
      <easiTextInput
        v-model="idArgs.value"
        v-if="idArgs.idType === 'national_id'"
        placeholder="Enter NIN"
      ></easiTextInput>
      <div class="justify-center my-3">
        <UploadComponent
          :label="`Upload clear picture of ${getIdType(idArgs.idType)}`"
          :dottedNew="true"
          @fileUrl="uploadFile = $event"
        />
      </div>

      <div class="w-full my-4 flex justify-center">
        <easiButton :loading="loading" block> <p class="w-40 text-sm">Submit</p></easiButton>
      </div>
    </form>
  </main>
</template>

<script setup>
import countryData from "@/utils/countries.json";
import lgData from "@/utils/localGovt.js";

import UploadComponent from "@/components/global/UploadComponent";
import { ref, computed, reactive } from "vue";
import { helperFunctions } from "@/composable/helperFunctions";
import { useDataStore } from "@/stores/data.js";
import { useToast } from "vue-toastification";

const toast = useToast();
const emit = defineEmits(["saved"]);
const { uploadFileToServer } = helperFunctions;
const store = useDataStore();
const { mutate } = store;

const loading = ref(false);
const uploadFile = ref(null);
const addressFile = ref(null);
const args = reactive({
  addressInformation: {
    country: "",
    state: "",
    // houseNumber: "",
    street: "",
    lgaName: "",
    city: "",
    // landmark: "",
    proofOfAddress: null,
  },
});

function validate() {
  if (uploadFile.value && addressFile.value) {
    return true;
  }
  toast.error("Upload all files");
  return false;
}

const idArgs = reactive({
  idType: "",
  front: "",
  back: "",
  value: "",
});
const countryOption = computed(() => {
  const data = countryData.map((count) => {
    return {
      label: count.name,
      value: count.name,
    };
  });
  return [data.find((ctr) => ctr && ctr.value && ctr.value === "Nigeria")] || [];
});

const stateOption = ref([]);

function updateCountry(country, key, subKey) {
  args[key][subKey] = country;
  const data = countryData.find((c) => c.name.toLowerCase() == country.toLowerCase());
  stateOption.value = data.states.map((sta) => {
    return {
      label: sta.name,
      value: sta.name.replace(/\s*State$/, ""),
    };
  });
}

const lgOption = ref([]);
function updateState(state, key, subKey) {
  args[key][subKey] = state;
  if (key === "addressInformation") {
    const data = lgData.find(
      (c) => c.state.toLowerCase() == state.toLowerCase() || c.state.toLowerCase == state.concat(" State").toLowerCase()
    );
    lgOption.value =
      data &&
      data.lgas.map((lg) => {
        return {
          label: lg,
          value: lg,
        };
      });
  }
}

const Options = ref([
  {
    label: "National ID",
    value: "national_id",
  },
  {
    label: "Voters Card",
    value: "voters_card",
  },
  //   {
  //     label: "NIN",
  //     value: "nin_slip",
  //   },
  {
    label: "Drivers License",
    value: "drivers_license",
  },
  {
    label: "International Passport",
    value: "international_passport",
  },
]);

const getIdType = (key) => {
  let obj = {
    national_id: "National ID",
    voters_card: "Voters Card",
    drivers_license: "Drivers License",
    international_passport: "International Passport",
  };

  return obj[key] ? obj[key] : "Passport page";
};

const createPersonalInformation = async (task) => {
  loading.value = true;

  try {
    if (uploadFile.value) {
      args.addressInformation.proofOfAddress = await uploadFileToServer(addressFile.value);
    }

    if (typeof args.addressInformation.proofOfAddress !== "string") {
      toast.error("Upload failed");
      throw new Error("upload failed");
    }
    let res = await mutate({
      endpoint: "CreatePersonalInformation",
      data: { input: args },
      service: "SETTINGS",
    });

    if (res && res.success) {
      //   if (task) {
      //     toast.success(res.message);
      //     emit("saved", true);
      //   }
    } else if (res && !res.success) {
      toast.error(res.message);
      throw new Error(res.message);
    }
  } catch (e) {
    console.log(e);
    throw new Error(e);
  } finally {
    loading.value = false;
  }
};

const createValidIdentityInformation = async () => {
  loading.value = true;

  try {
    if (uploadFile.value) {
      idArgs.front = await uploadFileToServer(uploadFile.value);
    }
    if (typeof idArgs.front !== "string") {
      toast.error("Upload failed");
      return;
    }
    const data = [idArgs];
    let res = await mutate({
      endpoint: "CreateValidIdentityInformation",
      data: { input: data },
      service: "SETTINGS",
    });
    if (res.success) {
      // emit("saved", true);
    } else {
      toast.error(res.message);
      throw new Error(res.message);
    }
  } catch (e) {
    console.log(e);
    throw new Error(e);
  } finally {
    loading.value = false;
  }
};

async function upgradeTier() {
  const res = await mutate({
    endpoint: "UpgradeTiers",
    data: { tier: "2" },
    service: "SETTINGS",
  });
  if (res && res.success) {
    toast.success("Tier upgraded successfully");
    emit("saved", true);
  } else if (res && !res.success) {
    toast.error(res.message);
  }
}

async function submitTier2Docs() {
  loading.value = true;
  try {
    if (addressFile.value) {
      args.addressInformation.proofOfAddress = await uploadFileToServer(addressFile.value);
    }

    if (typeof args.addressInformation.proofOfAddress !== "string") {
      toast.error("Upload failed");
      throw new Error("Upload failed");
    }
    if (uploadFile.value) {
      idArgs.front = await uploadFileToServer(uploadFile.value);
    }
    if (typeof idArgs.front !== "string") {
      toast.error("Upload failed");
      throw new Error("Upload failed");
    }

    const res = await mutate({
      endpoint: "AddTier2Docs",
      data: {
        personalInfo: {
          addressInformation: args.addressInformation,
          // birthInformation: {
          //   countryOfOrigin: null,
          //   dob: null,
          //   gender: null,
          //   lga: null,
          //   passportPhoto: null,
          //   stateOfOrigin: null,
          // },
        },
        idInfo: [idArgs],
      },
      service: "SETTINGS",
    });
    if (res && res.success) {
      // await upgradeTier();
      toast.success("Tier upgraded successfully");
      emit("saved", true);
    } else if (res && !res.success) {
      toast.error(res.message);
    }
  } catch (e) {
    console.log(e);
  } finally {
    loading.value = false;
  }
}

async function submit() {
  try {
    if (validate()) {
      // await createValidIdentityInformation();
      // await createPersonalInformation();

      // await upgradeTier();
      await submitTier2Docs();
    }
  } catch (e) {
    console.log(e);
  }
}
</script>

<style></style>
